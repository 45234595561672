/** Visual Studio Code Syntax highlighting styles */
.highlight { 
    background: #1E1E1E;
    color: #D4D4D4;
    border-radius: 8px;
    padding: 2px 8px;
}
.highlighter-rouge .highlight { background: #1E1E1E; }
.highlight .c { color: #618A51; }
.highlight .err { color: #a61717; background-color: #1E1E1E; }
.highlight .k { color: #589CD3; }
.highlight .o { font-weight: bold; }
.highlight .cm { color: #618A51; }
.highlight .cp { color: #999; font-weight: bold; }
.highlight .c1 { color: #618A51; }
.highlight .cs { color: #999; font-weight: bold; font-style: italic; }
.highlight .gd { color: #000; }
.highlight .gd .x { color: #000; }
.highlight .ge { font-style: italic; }
.highlight .gr { color: #a00; }
.highlight .gh { color: #999; }
.highlight .gi { color: #000; }
.highlight .gi .x { color: #000; }
.highlight .go { color: #888; }
.highlight .gp { color: #555; }
.highlight .gs { font-weight: bold; }
.highlight .gu { color: #aaa; }
.highlight .gt { color: #a00; }
.highlight .kc { color: #589CD3; }
.highlight .kd { color: #589CD3; }
.highlight .kp { color: #589CD3; }
.highlight .kr { color: #589CD3; }
.highlight .kt { color: #589CD3; }
.highlight .m { color: #099; }
.highlight .s { color: #CD917A; }
.highlight .na { color: #9DDBFB; }
.highlight .nb { color: #9DDBFB; }
.highlight .nc { color: #54C8B0; }
.highlight .no { color: #008080; }
.highlight .ni { color: #800080; }
.highlight .ne { color: #900; font-weight: bold; }
.highlight .nf { color: #DCDCAD; font-weight: bold; }
.highlight .nn { color: #54C8B0; }
.highlight .nt { color: #54C8B0; }
.highlight .nv { color: #008080; }
.highlight .ow { color: #589CD3; font-weight: bold; }
.highlight .w { color: #bbb; }
.highlight .mf { color: #099; }
.highlight .mh { color: #099; }
.highlight .mi { color: #B5CEA4; }
.highlight .mo { color: #099; }
.highlight .sb { color: #CD917A; }
.highlight .sc { color: #CD917A; }
.highlight .sd { color: #CD917A; }
.highlight .s2 { color: #CD917A; }
.highlight .se { color: #CD917A; }
.highlight .sh { color: #CD917A; }
.highlight .si { color: #CD917A; }
.highlight .sx { color: #CD917A; }
.highlight .sr { color: #009926; }
.highlight .s1 { color: #CD917A; }
.highlight .ss { color: #990073; }
.highlight .bp { color: #999; }
.highlight .vc { color: #008080; }
.highlight .vg { color: #008080; }
.highlight .vi { color: #008080; }
.highlight .il { color: #099; }
.highlight .ld { color: #e6db74; }
.highlight .nx { color: #9DDBFB; }
