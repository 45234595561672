.Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.Portrait {
    width: 400px;
    height: auto;
    filter: drop-shadow(0.15rem 0.15rem 0.2rem rgba(0, 0, 0, 0.5));
    padding: 5px;
}