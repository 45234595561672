body {
    background-image: url("/assets/images/bg-snow.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    box-sizing: border-box;
    
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0px;
    margin: 0px;
}

h1, h2, h3, h4 {
    color:black;
}

.Title {
    font-size: 350%;
    text-align: right;
    color: white;
    margin: 0px;
}

.TitleContainer {
    width: calc(35% - 5px);
}
.background_black {
    width: 35%;
    position: fixed;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: -10;
}

.Main {
    background: rgba(255,255,255,0.95);
    padding: 7px;
    margin: 0px 10%;
    line-height: 1.6;
}

nav {
    text-align: right;
    font-size: 150%;
    font-weight: bold;
    color: white;
}
nav > a:visited, a:link, a:active {
    color: white;
}
nav > a:hover {
    color: lightgray;
}

.Main > a, a:visited, a:link, a:active {
    text-decoration: none;
    color: black;
}

.Main > a:hover {
    color: darkgray;
}

a, a:visited, a:link, a:active {
    font-weight: bold;
    color: purple;
}

.Main > a:hover {
    color: cornflowerblue;
}

.NavCurrentPage {
    text-decoration: underline !important;
}

hr {
    border: solid lightgray;
}

.PostLeft {
    flex: 1 1 auto;
    margin-right: auto;
}
.PostRight {
    flex: 0 1 0;
}
.PostRight > img {
    height: 150px;
}

.Post {
    margin: 7px 10%;
    display: flex;
    flex-direction: row;
}
.PostTitle {
    text-align: left;
    margin: 0px;
}
.PostInfo {
    text-align: right;
    margin: 0px;
}

.PostImage {
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0.15rem 0.15rem 0.2rem rgba(0, 0, 0, 0.5));
}
.SmallPostImage {
    max-width: 45%;
    filter: drop-shadow(0.15rem 0.15rem 0.2rem rgba(0, 0, 0, 0.5));
}

.ImageFlexBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px;
}

.ImageFlexBox > img {
    padding: 5px;
}

.Footer {
    width: calc(35% - 5px);
    text-align: right;
    color: white;
}

.SocialMediaIcon {
    width: 50px;
    height: auto;
    border: none;
}

.SpellBackground {
    width: fit-content;
    background: #1E1E1E;
    color: #ffffff;
    border-radius: 8px;
    border: #3700ff solid 2px;
    padding: 2px 8px;
}
.SpellBackground > b {
    color: #ff0000;
}

.SpellBackground > b.duration {
    color: #ffff00;
}

pre {
    white-space: pre-wrap;
}

.RightQuote {
    text-align: right;
    margin: 0px;
}

.CenterQuote {
    text-align: center;
    margin: 0px;
}