/* GAMES PAGE */

/* Container for all games */
.GameContainer {
    display: flex;  
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0px 10%;
}

/* Display of individual game */
.GameDisplay {
    margin: 5px;
    padding: 5px;

    background-color: white;

    width: 275px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    
    text-align: left;
}

/* Title of game */
.GameTitle {
    text-align: center;

    margin: 0px;
    padding-bottom: 5px;
}

/* Image for game */
.GameImage {
    width: 100%;
    height: auto;
}

/* Read More text */
.GameReadMore {  
    margin-top: auto;

    align-self: flex-end;
    
    text-align: right;
}

/* PAGE FOR INDIVIDUAL GAME */

/* Game title on it's own page. */
.GameInfoTitle {
    padding: 0px;
    margin: 5px;
}

.GameInfoContainer {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px;
}

.GameInfoBox {
    flex-grow: 1.5;
}

.GamePostsBox {
    flex-shrink: 0;
}